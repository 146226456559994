<template>
  <div>
    <v-card v-if="project" flat outlined>
      <v-card-title
        >Project Members
        <v-spacer></v-spacer>
        <v-tooltip left>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              icon
              v-on="tooltip"
              href="https://docs.synpse.net/manage/projects"
              target="_blank"
              rel="noopener"
            >
              <v-icon color="grey" small>help</v-icon>
            </v-btn>
          </template>
          <span> View and manage access to the project </span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="projectMemberships"
          sort-by="key"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:top>
            <v-layout>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="700px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    class="primary--text mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Member
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <form v-on:submit.prevent="save()">
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.email"
                              label="Email/Github username"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              v-model="editedItem.roleIds"
                              :items="roles"
                              :menu-props="{ maxHeight: '400' }"
                              label="Roles"
                              item-text="name"
                              item-value="id"
                              multiple
                              hint="Select one or more roles that the new member will get"
                              persistent-hint
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-btn v-show="false" hidden type="submit"></v-btn>
                    </form>
                    <div v-show="projectMembershipError">
                      <v-alert type="error" outlined>
                        {{ projectMembershipError }}
                      </v-alert>
                    </div>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn class="primary--text" text @click="close">
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="primary--text" text @click="save">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline">Are you sure?</v-card-title>
                  <v-card-text v-if="editedItem.user">
                    This action will remove user with email '{{
                      editedItem.user.email
                    }}' from the project.
                  </v-card-text>
                  <v-card-actions>
                    <v-btn class="primary--text" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn class="primary--text" text @click="deleteItemConfirm"
                      >Delete</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-layout>
          </template>
          <template v-slot:[`item.user`]="{ item }">

            <span v-if="item.user.email.includes('users.noreply.github.com')">
              <span class="font-weight-thin">
                Github:
              </span>
              {{ item.user.name }}
            </span>
            <span v-else >
              {{ item.user.email }}
            </span>

            <span v-if="item.userId === user.id" class="font-weight-thin ml-3">
              (you)
            </span>
          </template>
          <template v-slot:[`item.roles`]="{ item }">
            <template v-for="(role, i) in item.roles">
              <v-chip
                :key="`${item.userId}-membership-${i}`"
                label
                class="primary--text mr-2"
                outlined
              >
                {{ role.name }}
              </v-chip>
            </template>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <!-- <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon> -->
            <v-icon
              :disabled="item.userId === user.id"
              small
              @click="deleteItem(item)"
              >mdi-delete</v-icon
            >
          </template>
          <template v-slot:no-data> No Project Members </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>


<script>

export default {
  props: {
    project: Object
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'User',
          align: 'start',
          sortable: true,
          value: 'user',
        },
        { text: 'Roles', value: 'roles' },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {

      }
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  computed: {
    user() {
      return this.$store.state.user.user
    },
    projectId() {
      return this.$route.params.project
    },
    roles() {
      return this.$store.state.role.roles
    },
    projectMemberships() {
      return this.$store.state.membership.projectMemberships
    },
    projectMembershipError() {
      return this.$store.state.membership.error
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Project Member' : 'Edit Project Member'
    },
    loading() {
      return this.$store.state.device.loading
    },
    error() {
      return this.$store.state.device.error
    }
  },

  mounted() {
    this.refresh()
  },

  methods: {
    refresh() {
      let q = {
        projectId: this.projectId,
      }
      this.$store.dispatch('ListRoles', q)
      this.$store.dispatch('ListMembershipsByProject', q)
    },
    editItem(item) {
      this.editedIndex = this.projectMember.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.projectMemberships.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      // Delete membership (avoid deleting yourself)
      let payload = {
        projectId: this.project.id,
        userId: this.editedItem.userId
      }
      await this.$store.dispatch('DeleteProjectMembership', payload)

      this.$store.dispatch('Notify', `Member removed from the project`)

      this.refresh()
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {
      let payload = {
        projectId: this.projectId,
        email: this.editedItem.email,
        username: null,
        userId: null,
        roleIds: this.editedItem.roleIds
      }

      // TODO(mjudeikis): Bit of UI hack. This form needs adjusting as now its 
      // hard to select roles and not to close window by mistake
      // if this is not an email - try username
      if (!this.validateEmail(payload.email) && !payload.email.includes("usr_")) {
        payload.username = payload.email
        payload.email = null
      } else if (payload.email.includes("usr_")){
        payload.userId = payload.email
        payload.email = null
      }
      

      await this.$store.dispatch('CreateProjectMembership', payload)

      if (this.projectMembershipError) {
        // Displaying error
        return
      }

      this.$store.dispatch('Notify', `Member '${payload.email}' added to the project`)

      this.refresh()

      this.close()
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  }
}
</script>